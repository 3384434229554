<template>
  <section>
    <ContextualSearch
      :filters-name="$t('accessPermissions')"
      :loading="loading"
      :filter-options="filters"
      :order-by-options="orderBy"
      :applied-filters="appliedFilters"
      :applied-sort="orderByValue"
      :applied-sort-direction="orderDirection"
      :multifilter="true"
      :start-value="searchInput"
      :show-time-period="true"
      :time-period-label="$t('creationDate')"
      :start-period="startPeriod"
      :end-period="endPeriod"
      @search="search"
      @sort-order-changed="sortOrderChanged"
    >
      <template #tags>
        <FilterTag
          v-for="(filter, f) in appliedFilters"
          :key="'filter_' + f"
          :text="getFilterText(filter)"
          :show-close-button="true"
          @close="deleteFilter(f)"
        ></FilterTag>
        <FilterTag
          v-if="startPeriod && endPeriod"
          :text="startPeriod + ' - ' + endPeriod"
          :show-close-button="true"
          @close="deleteDates()"
        ></FilterTag>
      </template>
    </ContextualSearch>

    <slot name="stats" />

    <fw-panel
      :title="$t('results')"
      :counter="users.length"
      :counter-total="totalUsers"
      boxed
      class="my-5"
      custom-class="bg-white p-0"
    >
      <fw-panel-info v-if="!loading && !users.length" type="basic" class="text-center my-5 text-gray-500">
        {{ $t('noPeople') }}
      </fw-panel-info>
      <RecycleScroller
        v-if="users.length > 0"
        v-slot="{ item }"
        key-field="key"
        :items="users"
        :item-size="57"
        :buffer="50"
      >
        <div class="p-1 border-b border-gray-100">
          <Person
            :key="item.key"
            :person="item"
            fullname
            shortname
            :clickable="true"
            :is-active="item.active"
            @click.native="openPerson(item)"
          >
            <template #options>
              <div class="flex-col gap-1.5 items-end text-right hidden md:flex">
                <div v-if="item.active" class="text-gray-500 text-xs">
                  {{ $t('activeIn') }} {{ item.created_date | formatDateTime }}
                </div>
                <div v-else-if="item.status == 'sent'" class="text-xs text-gray-500">
                  {{ $t('inviteSent') }}
                </div>
                <div v-else-if="item.status == 'waiting_send'" class="text-xs text-gray-500">
                  {{ $t('waitingInvite') }}
                </div>
                <div class="flex justify-end items-center">
                  <div
                    v-for="permission in item.permissions"
                    :key="permission"
                    class="inline-block px-2 py-0.5 m-0.5 rounded-md bg-opacity-30 text-xs font-medium bg-gray-300 text-gray-500"
                  >
                    {{ $t(`roles.${permission}`) }}
                  </div>
                </div>
              </div>
            </template>
          </Person>
        </div>
      </RecycleScroller>
    </fw-panel>

    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalUsers"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged"
    />

    <b-modal
      :active="activeModal !== null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <UserDetails
        v-if="activeModal === 'user_details'"
        :user="selectedUser"
        @updated-user="selectedUser = $event"
        @close="closeModal"
      />
    </b-modal>
  </section>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import UserDetails from '@/components/modals/UserDetails'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag.vue'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'

import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ManagePeople',
  components: {
    BlockPagination,
    Person,
    RecycleScroller,
    ContextualSearch,
    FilterTag,
    UserDetails,
  },
  data() {
    return {
      startPeriod: null,
      endPeriod: null,
      invites: [],
      users: [],
      user: {},
      loading: true,
      searchInput: '',
      totalPending: 0,
      activeModal: null,
      page: 1,
      totalPages: 1,
      totalUsers: 0,
      limit: 25,
      pagePending: 1,
      totalPagesPending: 1,
      orderByValue: 'full_name',
      orderDirection: 'ASC',
      appliedFilters: [],
      roles: {
        manager: this.$t('roles.manager'),
        jury: this.$t('roles.jury'),
        'procedure-manager': this.$t('roles.procedure-manager'),
      },
      filters: [
        {
          key: 'role',
          label: this.$t('accessPermissions'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
            {
              key: 'jury',
              label: this.$t('roles.jury'),
            },
            {
              key: 'procedure-manager',
              label: this.$t('roles.procedure-manager'),
            },
            {
              key: 'manager',
              label: this.$t('roles.manager'),
            },
            {
              key: 'admin',
              label: this.$t('roles.admin'),
            },
            {
              key: 'candidates',
              label: this.$t('candidates'),
            },
          ],
        },
        {
          key: 'status',
          label: this.$t('status.label'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
            {
              key: 'accepted',
              label: this.$t('status.accepted'),
            },
            {
              key: 'sent',
              label: this.$t('status.sent'),
            },
            {
              key: 'waiting_send',
              label: this.$t('status.waitingSend'),
            },
          ],
        },
      ],
      orderBy: [
        {
          key: 'full_name',
          label: this.$t('orderBy.name'),
          type: 'string',
        },
        {
          key: 'email',
          label: 'E-mail',
          type: 'string',
        },
        {
          key: 'number',
          label: this.$t('orderBy.number'),
          type: 'number',
        },
        {
          key: 'created_date',
          label: this.$t('orderBy.createdDate'),
          type: 'date',
        },
        {
          key: 'first_login',
          label: this.$t('orderBy.firstLogin'),
          type: 'date',
        },
      ],
      selectedUser: null,
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },
    permissions() {
      return this.$store.getters.userPermissions
    },
  },
  async created() {
    utils.sleep(100).then(() => {
      this.getUrlParams()
      this.getUsers()
    })
  },
  methods: {
    openPerson(user) {
      this.selectedUser = user
      this.openModal('user_details')
    },
    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getUsers()
    },
    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getUsers()
    },
    getFilterText(key) {
      var split = key.split(':')
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (var j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },
    search(data) {
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.$emit('searching')

      this.setUrlParams()
      this.getUsers()
    },
    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }
      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }
      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }
      if (this.$route.query.p) {
        this.page = this.$route.query.p
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }
    },
    setUrlParams() {
      var query = {}
      if (this.searchInput.length > 0) {
        query['q'] = this.searchInput
      }
      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }
      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }
      if (this.page > 1) {
        query['p'] = parseInt(this.page)
      }
      if (this.startPeriod && this.endPeriod) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }
      this.$router.push({ query: query })
    },
    filterChanged(newfilters) {
      this.appliedFilters = newfilters
      this.setUrlParams()
      this.getUsers()
    },
    sortOrderChanged(newsort) {
      if (newsort != null && newsort.key != null) {
        this.orderByValue = newsort.key.key
        this.orderDirection = newsort.direction
      }
      this.setUrlParams()
      this.getUsers()
    },
    inputChanged(newvalue) {
      console.log('inputChanged', newvalue)
      this.searchInput = newvalue
      this.setUrlParams()
      this.getUsers()
    },
    async getUsers() {
      this.loading = true
      let query = { limit: this.limit }

      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }
      if (this.page > 1) {
        query['page'] = this.page
      }
      if (this.appliedFilters.length > 0) {
        query['filters'] = this.appliedFilters.join(',')
      }
      if (this.searchInput.length > 0) {
        query['q'] = this.searchInput
      }

      if (this.startPeriod && this.endPeriod) {
        query['start_date'] = Dates.toDateString(this.startPeriod)
        query['end_date'] = Dates.toDateString(this.endPeriod)
      }

      const users = await this.api.getUsers(query)
      this.users = users.data
      this.totalPages = users.total_pages
      this.totalUsers = users.total
      this.page = users.active_page
      await utils.sleep(250)
      this.loading = false
    },
    openModal(type) {
      this.activeModal = type
    },
    closeModal() {
      this.activeModal = null
    },
    pageChanged(page) {
      if (page) this.page = page
      this.setUrlParams()
      this.getUsers()
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "all": "Todos",
    "people": "Pessoas",
    "results": "Resultados",
    "accessPermissions": "Permissões de acesso",
    "add": "Adicionar",
    "creationDate": "Data de criação",
    "noPeople": "Sem pessoas para apresentar.",
    "activeIn": "Ativo/a em",
    "inviteSent": "Convite enviado",
    "waitingInvite": "A aguardar envio de convite",
    "loadMoreData": "Mostrar mais resultados",
    "roles": {
      "admin": "Administrador",
      "manager": "Gestor",
      "jury": "Júri",
      "procedure-manager": "Secretariado"
    },
    "status": {
      "label": "Estado",
      "accepted": "Ativo",
      "sent": "Convite enviado",
      "waitingSend": "A aguardar envio de convite"
    },
    "orderBy": {
      "name": "Nome",
      "number": "Número mecanográfico",
      "createdDate": "Data de criação",
      "firstLogin": "Data de adesão"
    },
    "candidates": "Candidatos",
    "category": "Categoria",
    "university": "Universidade",
    "permissions": "Permissões",
    "inviteDate": "Data de convite",
    "accountAdded": "A conta de {user} ({email}) foi adicionada.",
    "userAlreadyExists": "User already exists",
    "userAlreadyExistsInfo": "O utilizador que tentou convidar já existe na Apply.",
    "error": "Ocorreu um erro",
    "errorInfo": "Ocorreu um erro não esperado{key}. Por favor, contacte a nossa equipa de suporte."
  },
  "en": {
    "all": "All",
    "people": "People",
    "results": "Results",
    "accessPermissions": "Access permissions",
    "add": "Add",
    "creationDate": "Creation Date",
    "noPeople": "No people to show.",
    "activeIn": "Active in",
    "inviteSent": "Invite sent",
    "waitingInvite": "Waiting invite",
    "loadMoreData": "Load more data",
    "roles": {
      "admin": "Administrator",
      "manager": "Manager",
      "jury": "Jury",
      "procedure-manager": "Secretariat"
    },
    "status": {
      "label": "Status",
      "accepted": "Active",
      "sent": "Invite sent",
      "waitingSend": "Waiting invite"
    },
    "orderBy": {
      "name": "Full name",
      "number": "Number",
      "createdDate": "Creation date",
      "firstLogin": "Joined date"
    },
    "candidates": "Candidates",
    "category": "Category",
    "university": "University",
    "permissions": "Permissions",
    "inviteDate": "Invite date",
    "accountAdded": "The account of {user} ({email}) was added.",
    "userAlreadyExists": "User already exists",
    "userAlreadyExistsInfo": "The user you tried to invite already exists in Apply.",
    "error": "An error has occurred",
    "errorInfo": "An unexpected error occurred {key}. Please contact our support team."
  }
}
</i18n>
