<template>
  <fw-layout>
    <template #main-sidebar>
      <SidebarManage />
    </template>
    <template #main-content>
      <fw-panel :title="$t('people')" featured>
        <template #toolbar>
          <span class="flex flex-1 justify-end">
            <fw-button type="transparent-primary" @click.native="openModal('add-users')">{{
              $t('add')
            }}</fw-button></span
          >
        </template>
        <ManagePeopleList :show-filters="false" @searching="hideStatsOnSearching = true">
          <template v-if="showStats && !hideStatsOnSearching" #stats>
            <PanelStats :stats="stats" :title="$t('numbers')" :loading="loadingStats" class="my-5 ml-5" />
          </template>
        </ManagePeopleList>
      </fw-panel>
    </template>
    <template #modals>
      <b-modal
        :active="activeModal !== null"
        scroll="keep"
        :can-cancel="true"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :width="700"
        :on-cancel="closeModal"
        :custom-class="'rounded-buefy-modal'"
      >
        <InviteUsersModal
          v-if="activeModal === 'add-users'"
          :with-roles="true"
          @close="closeModal"
          @save-invite="addUsers"
        ></InviteUsersModal>
      </b-modal>
    </template>
  </fw-layout>
</template>

<script>
import SidebarManage from '@/components/sidebars/SidebarManage'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import InviteUsersModal from '@/fw-modules/fw-core-vue/ui/components/modals/InviteUsersModal'
import ManagePeopleList from '@/components/panels/ManagePeopleList'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ManagePeople',
  components: {
    SidebarManage,
    ManagePeopleList,
    PanelStats,
    InviteUsersModal,
  },
  data() {
    return {
      stats: [],
      hideStatsOnSearching: false,
      loadingStats: true,
      activeModal: null,
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },

    userRoles() {
      return this.$store.getters.userPermissions
    },

    showStats() {
      return this.userRoles.isAdmin || this.userRoles.isManager
    },

    isManager() {
      return this.userRoles.isManager
    },
  },

  mounted() {
    this.loadStats()
  },

  methods: {
    openModal(modal) {
      this.activeModal = modal
    },
    closeModal() {
      this.activeModal = null
    },
    async loadStats() {
      this.loadingStats = true

      let data

      try {
        data = await this.api.getProceduresStats()
      } catch (error) {
        console.error('Error loadStats: ', error)
      }

      this.stats = [
        {
          label: null,
          value: data.users_stats?.users || 0,
          valueSuffix: null,
          description: this.$t('stats.users.members'),
          featured: false,
        },
        {
          label: null,
          value: data.users_stats?.registered_users || 0,
          valueSuffix: null,
          description: this.$t('stats.users.registered'),
          featured: false,
        },
      ]
      this.loadingStats = false
    },
    async addUsers(users, roles = null, type = 'add') {
      if (type == 'invite' && (!roles || !roles.length)) {
        roles = ['jury']
      }

      try {
        if (type == 'add') {
          const newUsers = await this.api.addUser({ users })
          console.log('createdUsers ==> ', newUsers)
          newUsers.forEach(element => {
            this.$buefy.snackbar.open({
              message: this.$t('accountAdded', { user: element.full_name, email: element.email }),
              type: 'is-primary',
              position: 'is-top-right',
              duration: 2000,
              queue: true,
            })
          })
        } else if (type == 'invite') {
          let user = users[0]
          const invitedUser = await this.api.inviteUser({
            email: user.email,
            name: user.name || user.display_name || user.full_name,
            roles: roles,
            no_email: false,
          })
          console.log('inviteUser ==>', invitedUser)
          this.$buefy.snackbar.open({
            message: this.$t('accountAdded', { user: invitedUser.full_name, email: invitedUser.email }),
            type: 'is-primary',
            position: 'is-top-right',
            duration: 2000,
            queue: true,
          })
        }
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)
        if (errorKey && errorKey == 'UserAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('userAlreadyExists'),
            message: this.$t('userAlreadyExistsInfo'),
            type: 'is-danger',
          })
        } else {
          this.$buefy.dialog.alert({
            title: this.$t('error'),
            message: this.$t('errorInfo', { key: errorKey ? `(${errorKey})` : '' }),
            type: 'is-danger',
          })
        }

        console.error(error)
      }
      this.closeModal()
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "numbers": "Números",
    "all": "Todos",
    "people": "Pessoas",
    "results": "Resultados",
    "accessPermissions": "Permissões de acesso",
    "add": "Adicionar",
    "creationDate": "Data de criação",
    "noPeople": "Sem pessoas para apresentar.",
    "activeIn": "Ativo/a em",
    "inviteSent": "Convite enviado",
    "waitingInvite": "A aguardar envio de convite",
    "loadMoreData": "Mostrar mais resultados",
    "roles": {
      "admin": "Administrador",
      "manager": "Gestor",
      "jury": "Júri",
      "procedure-manager": "Secretariado"
    },
    "status": {
      "label": "Estado",
      "accepted": "Ativo",
      "sent": "Convite enviado",
      "waitingSend": "A aguardar envio de convite"
    },
    "orderBy": {
      "name": "Nome",
      "number": "Número mecanográfico",
      "createdDate": "Data de criação",
      "firstLogin": "Data de adesão"
    },
    "candidates": "Candidatos",
    "category": "Categoria",
    "university": "Universidade",
    "permissions": "Permissões",
    "inviteDate": "Data de convite",
    "accountAdded": "A conta de {user} ({email}) foi adicionada.",
    "userAlreadyExists": "User already exists",
    "userAlreadyExistsInfo": "O utilizador que tentou convidar já existe na Apply.",
    "error": "Ocorreu um erro",
    "errorInfo": "Ocorreu um erro não esperado{key}. Por favor, contacte a nossa equipa de suporte.",
    "users": "Utilizadores",
    "stats": {
      "users": {
        "members": "Contas registadas",
        "registered": "Contas confirmadas"
      }
    }
  },
  "en": {
    "numbers": "Numbers",
    "all": "All",
    "people": "People",
    "results": "Results",
    "accessPermissions": "Access permissions",
    "add": "Add",
    "creationDate": "Creation Date",
    "noPeople": "No people to show.",
    "activeIn": "Active in",
    "inviteSent": "Invite sent",
    "waitingInvite": "Waiting invite",
    "loadMoreData": "Load more data",
    "roles": {
      "admin": "Administrator",
      "manager": "Manager",
      "jury": "Jury",
      "procedure-manager": "Secretariat"
    },
    "status": {
      "label": "Status",
      "accepted": "Active",
      "sent": "Invite sent",
      "waitingSend": "Waiting invite"
    },
    "orderBy": {
      "name": "Full name",
      "number": "Number",
      "createdDate": "Creation date",
      "firstLogin": "Joined date"
    },
    "candidates": "Candidates",
    "category": "Category",
    "university": "University",
    "permissions": "Permissions",
    "inviteDate": "Invite date",
    "accountAdded": "The account of {user} ({email}) was added.",
    "userAlreadyExists": "User already exists",
    "userAlreadyExistsInfo": "The user you tried to invite already exists in Apply.",
    "error": "An error has occurred",
    "errorInfo": "An unexpected error occurred {key}. Please contact our support team.",
    "users": "Users",
    "stats": {
      "users": {
        "members": "associated",
        "registered": "registered"
      }
    }
  }
}
</i18n>
